import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

interface ErrorMessageProps {
    message: string;
    setParentData: (message: string | null) => void;
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = (_event, reason: 'timeout' | 'clickaway') => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        props.setParentData(null);
    };

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={7000} onClose={handleClose}
                      anchorOrigin={{vertical: "top", horizontal: "center"}}>
                <MuiAlert elevation={7} variant="filled" severity="error">
                    {props.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

import * as React from 'react';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from './../graphql/mutations';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Analytics from '@aws-amplify/analytics';
import moment from "moment";
import { Spacer } from './common/Spacer';
import { mediaStyle } from './Subscription';

const useStyles = makeStyles((theme) => ({
    root: {
        
    },
    media: {
        ...mediaStyle
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

interface ExternalCourseProps {
    active?: boolean;
    activeUntil?: number; // timestamp
    extensionRequested?: boolean;
    extensionGranted?: boolean;
    providerName?: string;
    subscription?: string;
    availableAccounts?: number | null;
    peopleInQueue?: number | null;
    providerId: string;
    loadParentData: Function;
    description?: string | null;
    expectedAvailable?: number | null;
    setErrorMessage: Function;
    setInfoMessage: Function;
    queuePosition?: number | null;
}

const ExternalCourse: React.FunctionComponent<ExternalCourseProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    async function createSubscription(params){
        console.log(params);
        handleToggle();
        try {
            const result = await API.graphql(graphqlOperation(mutations.createSubscription, {
                providerName: params.providerName,
                platform: params.providerId
            }));
            console.log(result);
            if(result.hasOwnProperty('errors')) {
                // @ts-ignore
                props.setErrorMessage(loginResponse.errors[0]["message"]);
                handleClose();
                return;
            }
            Analytics.record({
                name: 'createSubscription',
                attributes: { platform: params.providerId }
            });
        } catch (err: any){
            props.setErrorMessage(err.errors[0]["message"]);
            handleClose();
        }
        // @ts-ignore
        props.loadParentData();
        props.setInfoMessage("You may login by clicking the logo of your subscription");
    }

    async function queue(platform){
        handleToggle();
        try {
            const result = await API.graphql(graphqlOperation(mutations.queueForPlatform, {
                platform: platform
            }));
            console.log(result);
            if(result.hasOwnProperty('errors')) {
                // @ts-ignore
                props.setErrorMessage(loginResponse.errors[0]["message"]);
                handleClose();
                return;
            } else {props.setInfoMessage("You will receive a notification when an account is available");}
            Analytics.record({
                name: 'queue',
                attributes: { platform: platform }
            });
        } catch (err: any){
            props.setErrorMessage(err.errors[0]["message"]);
        }
        // @ts-ignore
        handleClose();
        props.loadParentData();
    }


    async function queueCancel(platform){
        handleToggle();
        try {
            const result = await API.graphql(graphqlOperation(mutations.cancelQueueForPlatform, {
                platform: platform
            }));
            console.log(result);
            if(result.hasOwnProperty('errors')) {
                // @ts-ignore
                props.setErrorMessage(loginResponse.errors[0]["message"]);
                handleClose();
                return;
            }
            Analytics.record({
                name: 'cancelQueue',
                attributes: { platform: platform }
            });
        } catch (err: any){
            props.setErrorMessage(err.errors[0]["message"]);
        }
        // @ts-ignore
        handleClose();
        props.loadParentData();
    }

    let state, actionButton1, actionButton2;

    let interval;
    if(props.expectedAvailable
        && props.availableAccounts === 0 ) {
        const available = moment.unix(props.expectedAvailable as number);
        const now = moment();
        const days = available.diff(now, 'days');
        const hours = available.diff(now, 'hours');
        const mins = available.diff(now, "minutes");

        let intervalStr;
        if (days > 0) {
            intervalStr = `Available in ${days} days`;
        } else if (hours > 0) {
            intervalStr = `Available in ${hours} hours`;
        } else if (mins > 0){
            intervalStr = `Available in ${mins} mins`;
        }
        else {
            intervalStr = `Available soon`;
        }
        interval = <Chip label={intervalStr}/>;
    }

        state = <Grid container={true} spacing={2}>
            <Grid item>{interval}</Grid>
        </Grid>;

        if ((props.availableAccounts as number) > 0) {
            actionButton1 = <Button variant="contained" style={{width: '100%'}} color="primary" onClick={ (e) => createSubscription({providerName: props.providerName, providerId: props.providerId})}>
                Claim account
            </Button>;
            actionButton2 = <Typography align={"right"} style={{lineHeight: 2}} variant={"subtitle2"} component="p">{`${props.availableAccounts} accounts available`}</Typography>;
        } else {
            switch (props.queuePosition) {
                case undefined:
                case null:
                    actionButton1 = <Button variant="contained" style={{width: '100%'}} color="primary" onClick={()=> {queue(props.providerId);}}>
                        Sit in queue
                    </Button>;
                    actionButton2 = <Typography align={"right"} style={{lineHeight: 2}} variant={"subtitle2"} component="p">{(props.peopleInQueue as number).toString()+' people in queue'}</Typography>;
                    break;
                default:
                    actionButton1 = <Button variant="contained" style={{width: '100%'}} color="primary" onClick={()=> {queueCancel(props.providerId);}}>
                        Cancel queue
                    </Button>;
                    actionButton2 = <Typography align={"right"} style={{lineHeight: 2}} variant={"subtitle2"} component="p">Position {props.queuePosition.toString()} of {(props.peopleInQueue as number).toString()}</Typography>;
                    break;
            }
        }

    return (
        <>
            <Card className={classes.root} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{flexGrow: 1}}>
                    <CardMedia
                        className={classes.media}
                        image={"/course-providers/" + props.providerId!.toLowerCase() + ".png"}
                        title={props.providerName}
                    />
                    <CardContent style={{display: 'flex', flexDirection: 'column'}}>
                        <Typography gutterBottom variant="h6" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}>
                            {props.providerName}
                            {actionButton2}
                        </Typography>
                        <div style={{flexGrow: 1}}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.description}
                            </Typography>
                        </div>
                        <Spacer />
                        {state}
                    </CardContent>
                </div>
                <CardActions>
                    {actionButton1}
                </CardActions>
            </Card>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default ExternalCourse;
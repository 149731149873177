import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({

    footer: {
        marginTop: 40,
        marginBottom: 20,
        paddingRight: 10,
        textAlign: "right"
    }
});

const Footer: React.FunctionComponent<{}> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            Academy {new Date().getFullYear()}
        </div>
    );
}

export default Footer;
import * as React from 'react'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Analytics from '@aws-amplify/analytics'
import {Spacer} from './common/Spacer'
import {mediaStyle} from './Subscription'
import {Tooltip} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    ...mediaStyle,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

interface PerpetualSubscriptionProps {
  providerName?: string
  subscription?: string
  providerId: string
  url: string
  description?: string
}

const PerpetualSubscription: React.FunctionComponent<PerpetualSubscriptionProps> = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  async function loginSubscription() {
    handleToggle()
    window.open(props.url)
    Analytics.record({
      name: 'loginSubscription',
      attributes: {platform: props.providerId},
    })
    handleClose()
    return
  }

  const state = (
    <Grid container={true} spacing={2}>
      <Grid item xs={7}></Grid>
      <Grid item>
        <Typography align={'right'} style={{lineHeight: 2}}></Typography>
      </Grid>
    </Grid>
  )

  return (
    <>
      <Card
        className={classes.root}
        style={{height: '100%', display: 'flex', flexDirection: 'column'}}
      >
        <div style={{flexGrow: 1}}>
          <Tooltip title={`Click to start learning from ${props.providerName}`}>
            <CardMedia
              onClick={() => loginSubscription()}
              className={classes.media}
              image={
                '/course-providers/' + props.providerId.toLowerCase() + '.png'
              }
            />
          </Tooltip>
          <CardContent>
            <Typography gutterBottom variant="h6">
              {props.providerName}
            </Typography>
            <div style={{flexGrow: 1}}>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.description}
              </Typography>
            </div>
            <Spacer />
            {state}
          </CardContent>
        </div>
      </Card>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default PerpetualSubscription

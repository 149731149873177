import React, {useEffect, useRef} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function FirefoxExtensionVerification() {
  const [open, setOpen] = React.useState(false)

  const receivedPongRef = useRef(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handler = event => {
      if (
        event.source === window &&
        event?.data?.direction === 'from-firefox-ext' &&
        event?.data?.message === 'pong'
      ) {
        receivedPongRef.current = true
      }
    }
    window.addEventListener('message', handler)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  useEffect(() => {
    window.postMessage(
      {
        direction: 'to-firefox-ext',
        session: {
          action: 'ping',
        },
      },
      '*',
    )

    const timerId = setTimeout(() => {
      if (!receivedPongRef.current) {
        handleClickOpen()
      }
    }, 5_000)

    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Firefox extension</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please install this Firefox{' '}
          <a
            href="https://chrome.google.com/webstore/detail/academy-helper/pmhjkffhpgjamdkdccmjoejeaniepgcd?hl=en-GB&authuser=0"
            target="_blank"
            rel="noopener noreferrer"
          >
            extension
          </a>{' '}
          in order to login to external course providers.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import * as React from 'react';
import Card from '@material-ui/core/Card';
import {makeStyles} from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {API, graphqlOperation} from 'aws-amplify';
import * as mutations from './../graphql/mutations';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Analytics from "@aws-amplify/analytics";
import { mediaStyle } from './Subscription';

const useStyles = makeStyles((theme) =>({
    root: {
    },
    media: {
        ...mediaStyle
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

interface ExternalCourseProps {
    providerName?: string;
    providerId?: string | null;
    loadParentData: Function;
    setErrorMessage: Function;
    setInfoMessage: Function;
}

const PendingSubscription: React.FunctionComponent<ExternalCourseProps> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    async function createSubscriptionFromQueue(platform){
        handleToggle();
        try {
            const result = await API.graphql(graphqlOperation(mutations.createSubscriptionFromQueue, {
                providerName: platform
            }));
            console.log(result);
            if(result.hasOwnProperty('errors')) {
                // @ts-ignore
                props.setErrorMessage(loginResponse.errors[0]["message"]);
                handleClose();
                return;
            }
            Analytics.record({
                name: 'createSubscriptionFromQueue',
                attributes: { platform: platform }
            });
        } catch (err: any){
            props.setErrorMessage(err.errors[0]["message"]);
        }
        // @ts-ignore
        handleClose();
        props.loadParentData();
    }
    async function cancelQueue(platform){
        handleToggle();
        try {
            const result = await API.graphql(graphqlOperation(mutations.cancelQueueForPlatform, {
                platform: platform
            }));
            console.log(result);
            if(result.hasOwnProperty('errors')) {
                // @ts-ignore
                props.setErrorMessage(loginResponse.errors[0]["message"]);
                handleClose();
                return;
            }
            Analytics.record({
                name: 'cancelQueue',
                attributes: { platform: platform }
            });
        } catch (err: any){
            props.setErrorMessage(err.errors[0]["message"]);
        }
        // @ts-ignore
        handleClose();
        props.loadParentData();
    }

    let actionButton1, actionButton2;

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

        actionButton1 = <Button variant="contained" color="primary" onClick={()=>createSubscriptionFromQueue(props.providerId)}>
            Claim subscription
        </Button>;

        actionButton2= <Button variant="contained" color="secondary" onClick={ (e) => cancelQueue(props.providerId)}>
            Cancel
        </Button>

    return (
        <>
            <Card className={classes.root} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div style={{flexGrow: 1}}>
                    <CardMedia
                        className={classes.media}
                        image={"/course-providers/" + props.providerId!.toLowerCase() + ".png"}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h6">
                            {capitalizeFirstLetter(props.providerId)}
                        </Typography>
                    </CardContent>
                </div>
                <CardActions>
                    <Grid container={true} spacing={2}>
                        <Grid item>
                            {actionButton1}</Grid>
                        <Grid item>{actionButton2}</Grid>
                    </Grid>
                </CardActions>
            </Card>
            <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default PendingSubscription;
import React, {useCallback} from 'react'
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import Dashboard from './pages/Dashboard'

import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Grid from '@material-ui/core/Grid'
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import {Auth, appendToCognitoUserAgent} from '@aws-amplify/auth'
import {AmplifyAuthenticator, AmplifySignIn} from '@aws-amplify/ui-react'
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components'
import Analytics from '@aws-amplify/analytics'

console.log(awsconfig)
Amplify.configure(awsconfig)

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
      },
    },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
  },
  control: {
    padding: theme.spacing(0),
  },
}))

function App() {
  const classes = useStyles()
  const [signedIn, setSignedIn] = React.useState(false)

  const checkUser = useCallback(() => {
    setUser()

    return onAuthUIStateChange(authState => {
      if (authState === AuthState.SignedIn) {
        setSignedIn(true)
      } else if (authState === AuthState.SignedOut) {
        setSignedIn(false)
      }
    })
  }, [])

  React.useEffect(() => {
    appendToCognitoUserAgent('withAuthenticator')
    // checkUser returns an "unsubscribe" function to stop side-effects
    return checkUser()
  }, [checkUser])

  async function setUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        setSignedIn(true)
        console.log(
          user.username,
          user.attributes['custom:company'],
          user.attributes['custom:office'],
        )
        Analytics.updateEndpoint({
          address: user.username, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
          userAttributes: {
            // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
            company: [user.attributes['custom:company']],
            office: [user.attributes['custom:office']],
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {signedIn ? (
        <div className="App">
          <Router>
            <Grid container className={classes.root} spacing={0}>
              <Grid item xs={12}>
                <Header />
              </Grid>
              <Switch>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
              </Switch>
              <Redirect exact from="/" to="/dashboard" />
              <Grid item xs={12}>
                <Footer />
              </Grid>
            </Grid>
          </Router>
        </div>
      ) : (
        <div className="App">
          <AmplifyAuthenticator>
            <AmplifySignIn
              headerText="Login with your company credentials"
              slot="sign-in"
              formFields={[
                {type: 'hidden', inputProps: {style: {display: 'none'}}},
              ]}
            >
              <div slot="secondary-footer-content"></div>

              <div slot="primary-footer-content"></div>
              <div slot="footer"></div>
            </AmplifySignIn>
          </AmplifyAuthenticator>
        </div>
      )}
    </ThemeProvider>
  )
}

export default App

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
import * as env from "./environment";
const awsmobile = {
    "aws_project_region": env.REGION,
    "aws_cognito_identity_pool_id":  env.IDENTITY_POOL_ID,
    "aws_cognito_region": env.REGION,
    "aws_user_pools_id": env.USER_POOL_ID,
    "aws_user_pools_web_client_id": env.USER_POOL_WEB_CLIENT_ID,
    "oauth": {
        domain: env.COGNITO_URL,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: env.REDIRECT_URL,
        redirectSignOut: env.REDIRECT_URL,
        responseType: 'token'
    },
    "aws_appsync_graphqlEndpoint": env.GRAPHQL_ENDPOINT,
    "aws_appsync_region": env.REGION,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "academy-20200821142840-hostingbucket-dev",
    "aws_content_delivery_bucket_region": env.REGION,
    "aws_content_delivery_url": "https://academy.pentalog.com",
    Analytics: {
        AWSPinpoint: {
            // Amazon Pinpoint App Client ID
            appId: env.PINPOINT_APP_ID,
            // Amazon service region
            region: env.REGION,
            mandatorySignIn: false,
            endpoint: {
                attributes: {}
            }
        }
    }
    //
    // Analytics: {
    //     AWSPinpoint: {
    //         // OPTIONAL -  Amazon Pinpoint App Client ID
    //         appId: 'XXXXXXXXXXabcdefghij1234567890ab',
    //         // OPTIONAL -  Amazon service region
    //         region: 'eu-central-1',
    //         // OPTIONAL -  Customized endpoint
    //         endpointId: 'XXXXXXXXXXXX',
    //         // OPTIONAL - Default Endpoint Information
    //         endpoint: {
    //             address: 'xxxxxxx', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
    //             attributes: {
    //                 // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
    //                 hobbies: ['piano', 'hiking'],
    //             },
    //             channelType: 'APNS', // The channel type. Valid values: APNS, GCM
    //             demographic: {
    //                 appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
    //                 locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
    //                 make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
    //                 model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
    //                 modelVersion: 'xxxxxx', // The model version of the endpoint device.
    //                 platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
    //                 platformVersion: 'xxxxxx', // The platform version of the endpoint device.
    //                 timezone: 'xxxxxx' // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
    //             },
    //             location: {
    //                 city: 'xxxxxx', // The city where the endpoint is located.
    //                 country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
    //                 latitude: 0, // The latitude of the endpoint location, rounded to one decimal place.
    //                 longitude: 0, // The longitude of the endpoint location, rounded to one decimal place.
    //                 postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
    //                 region: 'xxxxxx' // The region of the endpoint location. For example, in the United States, this corresponds to a state.
    //             },
    //             metrics: {
    //                 // Custom metrics that your app reports to Amazon Pinpoint.
    //             },
    //             /** Indicates whether a user has opted out of receiving messages with one of the following values:
    //              * ALL - User has opted out of all messages.
    //              * NONE - Users has not opted out and receives all messages.
    //              */
    //             optOut: 'NONE',
    //             // Customized userId
    //             userId: 'XXXXXXXXXXXX',
    //             // User attributes
    //             userAttributes: {
    //                 interests: ['football', 'basketball', 'AWS']
    //                 // ...
    //             }
    //         },
    //
    //         // Buffer settings used for reporting analytics events.
    //         // OPTIONAL - The buffer size for events in number of items.
    //         bufferSize: 1000,
    //
    //         // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    //         flushInterval: 5000, // 5s
    //
    //         // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    //         flushSize: 100,
    //
    //         // OPTIONAL - The limit for failed recording retries.
    //         resendLimit: 5
    //     }
    // }
};


export default awsmobile;

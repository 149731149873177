/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription($subscription: String) {
    cancelSubscription(subscription: $subscription)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $condition: ModelAccountConditionInput
    $input: CreateAccountInput!
  ) {
    createAccount(condition: $condition, input: $input) {
      createdAt
      id
      inUse
      password
      platform
      updatedAt
      username
    }
  }
`;
export const createPlatform = /* GraphQL */ `
  mutation CreatePlatform(
    $condition: ModelPlatformConditionInput
    $input: CreatePlatformInput!
  ) {
    createPlatform(condition: $condition, input: $input) {
      availableAccounts
      createdAt
      description
      id
      peopleInQueue
      providerName
      updatedAt
      expectedAvailable
      position {
        platform
        position
        user
        createdAt
        updatedAt
        pendingSince
      }
    }
  }
`;
export const createSubscription = /* GraphQL */ `
  mutation CreateSubscription($platform: String, $providerName: String) {
    createSubscription(platform: $platform, providerName: $providerName)
  }
`;
export const createSubscriptionFromQueue = /* GraphQL */ `
  mutation CreateSubscriptionFromQueue($providerName: String) {
    createSubscriptionFromQueue(providerName: $providerName)
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $condition: ModelAccountConditionInput
    $input: DeleteAccountInput!
  ) {
    deleteAccount(condition: $condition, input: $input) {
      createdAt
      id
      inUse
      password
      platform
      updatedAt
      username
    }
  }
`;
export const deletePlatform = /* GraphQL */ `
  mutation DeletePlatform(
    $condition: ModelPlatformConditionInput
    $input: DeletePlatformInput!
  ) {
    deletePlatform(condition: $condition, input: $input) {
      availableAccounts
      createdAt
      description
      id
      peopleInQueue
      providerName
      updatedAt
      expectedAvailable
      position {
        platform
        position
        user
        createdAt
        updatedAt
        pendingSince
      }
    }
  }
`;
export const loginSubscription = /* GraphQL */ `
  mutation LoginSubscription($subscription: String) {
    loginSubscription(subscription: $subscription) {
      cookies
      storage
      url
    }
  }
`;
export const requestExtension = /* GraphQL */ `
  mutation RequestExtension($subscription: String) {
    requestExtension(subscription: $subscription) {
      error
      message
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $condition: ModelAccountConditionInput
    $input: UpdateAccountInput!
  ) {
    updateAccount(condition: $condition, input: $input) {
      createdAt
      id
      inUse
      password
      platform
      updatedAt
      username
    }
  }
`;
export const updatePlatform = /* GraphQL */ `
  mutation UpdatePlatform(
    $condition: ModelPlatformConditionInput
    $input: UpdatePlatformInput!
  ) {
    updatePlatform(condition: $condition, input: $input) {
      availableAccounts
      createdAt
      description
      id
      peopleInQueue
      providerName
      updatedAt
      expectedAvailable
      position {
        platform
        position
        user
        createdAt
        updatedAt
        pendingSince
      }
    }
  }
`;
export const queueForPlatform = /* GraphQL */ `
  mutation QueueForPlatform($platform: String) {
    queueForPlatform(platform: $platform)
  }
`;
export const cancelQueueForPlatform = /* GraphQL */ `
  mutation CancelQueueForPlatform($platform: String) {
    cancelQueueForPlatform(platform: $platform)
  }
`;

import * as React from 'react'
import Card from '@material-ui/core/Card'
import {makeStyles} from '@material-ui/core/styles'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {API, graphqlOperation} from 'aws-amplify'
import * as mutations from './../graphql/mutations'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Analytics from '@aws-amplify/analytics'
import {Tooltip} from '@material-ui/core'

const platformUrls = {
  mindtools: ['https://app.mindtools.com/'],
  oreilly: ['https://www.oreilly.com/'],
  raywenderlich: ['https://www.raywenderlich.com/'],
  pluralsight: ['https://app.pluralsight.com/'],
  frontend: ['https://frontendmasters.com/'],
  udemy: ['https://udemy.com/', 'https://pentalog.udemy.com/'],
}

export const mediaStyle = {
  height: 120,
  cursor: 'pointer',
  backgroundSize: 'contain',
  backgroundOrigin: 'content-box',
  padding: 10,
} as const

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    ...mediaStyle,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

interface ExternalCourseProps {
  active: boolean
  activeUntil?: number // timestamp
  extensionRequested?: boolean | null
  extensionGranted?: boolean | null
  providerName?: string
  subscription?: string
  providerId?: string | null
  loadParentData: Function
  setErrorMessage: Function
  setInfoMessage: Function
}

const Subscription: React.FunctionComponent<ExternalCourseProps> = props => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen(!open)
  }

  const isFirefox = navigator.userAgent.match(/Firefox/)

  async function loginSubscription(subscription, platform) {
    try {
      handleToggle()
      const loginResponse = await API.graphql(
        graphqlOperation(mutations.loginSubscription, {
          subscription: subscription,
        }),
      )
      // The ID of the extension we want to talk to.
      //chrome dev
      // let editorExtensionId = "ldfcbdlbmejnldfnfacadgfepgfdjbin";
      let editorExtensionId = 'pmhjkffhpgjamdkdccmjoejeaniepgcd'

      if (loginResponse.hasOwnProperty('errors')) {
        // @ts-ignore
        props.setErrorMessage(loginResponse.errors[0]['message'])
        Analytics.record({
          name: 'loginSubscriptionError',
          attributes: {platform: platform},
        })
        handleClose()
        props.loadParentData()
        return
      }

      // @ts-ignore
      var session = loginResponse.data.loginSubscription
      session.action = 'set'

      if (isFirefox) {
        window.postMessage(
          {
            direction: 'to-firefox-ext',
            session,
          },
          '*',
        )
      } else {
        // @ts-ignore
        chrome.runtime.sendMessage(editorExtensionId, session, function(
          response,
        ) {
          console.log(response)
        })
      }

      Analytics.record({
        name: 'loginSubscription',
        attributes: {platform: platform},
      })

      handleClose()
    } catch (err) {
      console.log(err)
      props.setErrorMessage((err as any)?.errors[0]['message'])
      handleClose()
      props.loadParentData()
    }
  }

  async function cancelSubscription(subscription, platform) {
    handleToggle()
    console.log(subscription)
    //chrome dev
    // var editorExtensionId = "ldfcbdlbmejnldfnfacadgfepgfdjbin";
    let editorExtensionId = 'pmhjkffhpgjamdkdccmjoejeaniepgcd'

    const session = {
      action: 'clear',
      url: platformUrls[platform],
      cookies: JSON.stringify([]),
    }

    if (isFirefox) {
      window.postMessage(
        {
          direction: 'to-firefox-ext',
          session,
        },
        '*',
      )
    } else {
      // TODO: other browser fallback - chrome may not exist at this point
      //@ts-ignore
      chrome.runtime.sendMessage(editorExtensionId, session, function(
        response,
      ) {
        console.log(response)
      })
    }

    const result = await API.graphql(
      graphqlOperation(mutations.cancelSubscription, {
        subscription: subscription,
      }),
    )
    console.log(result)
    Analytics.record({
      name: 'cancelSubscription',
      attributes: {platform: platform},
    })
    // @ts-ignore
    props.loadParentData()
    handleClose()
  }

  async function requestExtension(subscription, platform) {
    handleToggle()
    console.log(subscription)
    const data = await API.graphql(
      graphqlOperation(mutations.requestExtension, {
        subscription: subscription,
      }),
    )
    console.log(data)
    // @ts-ignore
    props.loadParentData()
    if (data.hasOwnProperty('error')) {
      // @ts-ignore
      props.setErrorMessage(data.message)
      handleClose()
      props.loadParentData()
      return
    }

    Analytics.record({
      name: 'requestExtension',
      attributes: {platform: platform},
    })
    // @ts-ignore
    props.setInfoMessage(data.message)
    handleClose()
  }

  let interval, actionButton1, actionButton2
  if (props.active) {
    const activeUntil = moment.unix(props.activeUntil as number)
    const now = moment()
    const days = activeUntil.diff(now, 'days')
    const hours = activeUntil.diff(now, 'hours')

    if (days > 0) {
      interval = `${days} days left`
    } else if (hours < 0) {
      interval = ''
    } else {
      interval = `${hours} hours left`
    }

    actionButton1 = (
      <Button
        variant="contained"
        color="primary"
        onClick={() => requestExtension(props.subscription, props.providerId)}
      >
        Request extension
      </Button>
    )

    if (props.extensionRequested === true) {
      actionButton1 = (
        <Button variant="contained" color="primary" disabled={true}>
          Extension requested
        </Button>
      )
    }

    actionButton2 = (
      <Button
        variant="contained"
        color="secondary"
        onClick={e => cancelSubscription(props.subscription, props.providerId)}
      >
        Cancel
      </Button>
    )
  }

  return (
    <div>
      <Card className={classes.root}>
        <div>
          <Tooltip title={`Click to start learning from ${props.providerName}`}>
            <CardMedia
              onClick={() =>
                loginSubscription(props.subscription, props.providerId)
              }
              className={classes.media}
              image={
                '/course-providers/' + props.providerId!.toLowerCase() + '.png'
              }
            />
          </Tooltip>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              {props.providerName}
            </Typography>
            <Typography
              style={{lineHeight: 2}}
              color="textSecondary"
              variant={'subtitle2'}
            >
              {interval}
            </Typography>
          </CardContent>
        </div>
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
          }}
        >
          {actionButton1}
          {actionButton2}
        </CardActions>
      </Card>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default Subscription

import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Container} from '@material-ui/core'
import ExternalCourse from '../components/ExternalCourse'
import {API, graphqlOperation} from 'aws-amplify'
import * as queries from './../graphql/queries'
import {useEffect, useState} from 'react'
import Subscription from '../components/Subscription'
import PerpetualSubscription from '../components/PerpetualSubscription'
import ChromeExtensionVerification from '../components/common/ChromeExtensionVerification'
import FirefoxExtensionVerification from '../components/common/FirefoxExtensionVerification'
import {ErrorMessage} from './../components/common/ErrorMessage'
import {InfoMessage} from './../components/common/InfoMessage'
import PendingSubscription from '../components/PendingSubscription'
import {Spacer} from '../components/common/Spacer'
import {
  PlatformSubscription,
  Queue,
  Subscription as SubscriptionType,
} from '../API'

type Platform = NonNullable<Required<PlatformSubscription>['platform']>

const Dashboard: React.FunctionComponent<{}> = () => {
  const [platforms, setPlatforms] = useState<Platform[]>([])
  const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([])
  const [queues, setQueues] = useState<Queue[]>([])

  const [errorMessage, setErrorMessage] = useState<string | null>()
  const [infoMessage, setInfoMessage] = useState<string | null>()

  async function loadData() {
    const querySubscriptions = await API.graphql(
      graphqlOperation(queries.listSubscriptions, {
        filter: {active: {eq: true}},
      }),
    )
    const activePlatformsFilter = []
    // @ts-ignore
    querySubscriptions.data.listSubscriptions.items.forEach(
      (subscription, i) => {
        // @ts-ignore
        activePlatformsFilter.push({id: {ne: subscription.platformId}})
        console.log(subscription, i)
      },
    )
    let filterPlatforms = {}
    if (activePlatformsFilter.length > 0) {
      filterPlatforms = {filter: {id: {ne: ''}, and: activePlatformsFilter}}
    }
    const queryPlatforms = await API.graphql(
      graphqlOperation(queries.listPlatforms, filterPlatforms),
    )
    // @ts-ignore
    setPlatforms(queryPlatforms.data.listPlatforms.items)
    // @ts-ignore
    setSubscriptions(querySubscriptions.data.listSubscriptions.items)

    const queryQueues = await API.graphql(
      graphqlOperation(queries.listQueues, {filter: {pendingSince: {gt: 0}}}),
    )
    console.log(
      queryPlatforms,
      querySubscriptions,
      activePlatformsFilter,
      queryQueues,
    )
    //@ts-ignore
    setQueues(queryQueues.data.listQueues.items)

    return true as const
  }

  useEffect(() => {
    loadData()
  }, [])

  const isFirefox = navigator.userAgent.match(/Firefox/)

  return (
    <React.Fragment>
      {isFirefox ? (
        <FirefoxExtensionVerification />
      ) : (
        <ChromeExtensionVerification />
      )}
      <Grid item xs={12}>
        <Container
          style={{padding: 20, margin: 0, textAlign: 'left', maxWidth: 'none'}}
        >
          {queues.length !== 0 && (
            <>
              <Typography variant="h6">Pending subscriptions</Typography>
              <Spacer />
              <Grid container={true} spacing={2}>
                {queues.map(queue => (
                  <Grid
                    item
                    key={`queue${queue.platform}`}
                    xs={12}
                    sm={6}
                    md={3}
                  >
                    <PendingSubscription
                      providerId={queue.platform}
                      loadParentData={loadData}
                      setErrorMessage={setErrorMessage}
                      setInfoMessage={setInfoMessage}
                    />
                  </Grid>
                ))}
              </Grid>
              <Spacer size="3em" />
            </>
          )}
          <Typography variant={'h6'}>Your active subscriptions</Typography>
          <Spacer size="2em" />
          <Grid container={true} spacing={2}>
            <Grid item key="lms" xs={12} sm={6} md={2}>
              <PerpetualSubscription
                providerName="TalentLMS"
                providerId="talentlms"
                url="https://lms.pentalog.com/dashboard"
                description="Pentalog specific content, Security trainings, soft skills & more."
              />
            </Grid>
            <Grid item key="globantuniversity" xs={12} sm={6} md={2}><PerpetualSubscription providerName="Globant University"  providerId="globantuniversity" url="https://university.globant.com/" description="Globant University provides all Globers with on-the-job learning opportunities to master new skills and be able to boost a culture of continuous learning."/></Grid>
            <Grid item key="knowbe4" xs={12} sm={6} md={2}><PerpetualSubscription providerName="KnowBe4"  providerId="knowbe4" url="https://training.knowbe4.com/ui/login" description="Compliance and security courses."/></Grid>

            {subscriptions.map(subscription => {
              return (
                <Grid item xs={12} sm={6} md={2} key={subscription.id}>
                  <Subscription
                    providerName={subscription.providerName}
                    active={!!subscription.active}
                    providerId={subscription.platformId}
                    activeUntil={subscription.activeUntil}
                    extensionRequested={subscription.extensionRequested}
                    extensionGranted={subscription.extensionGranted}
                    loadParentData={loadData}
                    subscription={subscription.id}
                    setErrorMessage={setErrorMessage}
                    setInfoMessage={setInfoMessage}
                  />
                </Grid>
              )
            })}
          </Grid>
          <Spacer size="3em" />
          <Typography variant={'h6'}>Available E-Learning platforms</Typography>
          <Spacer />
          {platforms.length ? (
            <Grid container={true} spacing={2}>
              {/* <Grid item xs={12} sm={6} md={3}><ExternalCourse providerId="frontend"
                                        loadParentData={loadData}
                                        setErrorMessage={setErrorMessage}
                                        setInfoMessage={setInfoMessage} providerName="Provider" active={false} availableAccounts={1}/></Grid>
                        <Grid item xs={12} sm={6} md={3}><ExternalCourse providerId="frontend"  
                                        loadParentData={loadData}
                                        setErrorMessage={setErrorMessage}
                                        setInfoMessage={setInfoMessage} providerName="Provider" active={false} availableAccounts={2}/></Grid>
                        <Grid item xs={12} sm={6} md={3}><ExternalCourse providerId="frontend"   
                                        loadParentData={loadData}
                                        setErrorMessage={setErrorMessage}
                                        setInfoMessage={setInfoMessage} providerName="Provider" active={false} availableAccounts={0} peopleInQueue={10}/></Grid>
                        <Grid item xs={12} sm={6} md={3}><ExternalCourse providerId="frontend"   
                                        loadParentData={loadData}
                                        setErrorMessage={setErrorMessage}
                                        setInfoMessage={setInfoMessage} providerName="Provider" active={false} availableAccounts={0} peopleInQueue={7}/></Grid> */}
              {platforms.map(platform => {
                const position = platform.position
                  ? platform.position.position
                  : null
                return (
                  <Grid item key={platform.id} xs={12} sm={6} md={2}>
                    <ExternalCourse
                      providerName={platform.providerName}
                      active={false}
                      availableAccounts={platform.availableAccounts}
                      peopleInQueue={platform.peopleInQueue}
                      providerId={platform.id}
                      loadParentData={loadData}
                      description={platform.description}
                      expectedAvailable={platform.expectedAvailable}
                      setErrorMessage={setErrorMessage}
                      setInfoMessage={setInfoMessage}
                      queuePosition={position}
                    />
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <Typography variant="subtitle2">No available platforms</Typography>
          )}
        </Container>
      </Grid>
      <div>
        {errorMessage && (
          <ErrorMessage
            message={errorMessage}
            setParentData={setErrorMessage}
          />
        )}
        {infoMessage && (
          <InfoMessage message={infoMessage} setParentData={setInfoMessage} />
        )}
      </div>
    </React.Fragment>
  )
}

export default Dashboard
